import React, { Component } from 'react';
import './Work.scss'

class Work extends Component {
  render() {
    return (
      <>
        <div id='about'>
          <div className='infoPadding'>
            <p className='infoBody'>
              Sorry for the inconvenience! Website under construction. 
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Work;